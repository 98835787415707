export const store = {
  id: '1',
  name: 'Starbucks Yunuslar',
  slug: 'starbucks-yunuslar-izmir',
  categories: [
    {
      id: '1',
      name: 'Kahve',
      slug: 'kahve',
    },
  ],
  location: {
    lat: 1,
    lng: 1,
  },
  address: {
    city: 'Izmir',
    country: 'Turkey',
    countryCode: 'TR',
    postalCode: '35600',
    street: '',
    text: '',
  },
  images: [
    'https://picsum.photos/200',
  ],
  properties: [
    {
      id: '1',
      name: 'Engelli Ozellikleri',
      values: [
        {
          id: '1',
          value: 'Tekerlikli Sandalye Girisi',
        },
      ],
    },
  ],
  workingHours: {
    monday: [9, 24],
    tuesday: [9, 24],
    wednesday: [9, 24],
    thursday: [9, 24],
    friday: [9, 24],
    saturday: [9, 24],
    sunday: [9, 22],
  },
  people: [
    {
      name: 'Bartu OZEL',
      email: 'bartuozel@gmail.com',
      phoneNumber: '+905456409291',
      type: 'ADMIN',
    },
    {
      name: 'Sercan KULAHCI',
      email: 'sercankulahci@gmail.com',
      phoneNumber: '+905456409291',
      type: 'WAITER',
    },
  ],
  website: 'https://starbucks.com',
};
