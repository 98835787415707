import Vue from 'vue';
import VueRouter from 'vue-router';
import firebase from '../firebase';
import Dashboard from '../views/app/Dashboard.vue';
import ProductAdd from '../views/app/product/ProductAdd.vue';
import ProductDetails from '../views/app/product/ProductDetails.vue';
import Store from '../views/app/store/Store.vue';
import StoreAdd from '../views/app/store/StoreAdd.vue';
import StoreListing from '../views/app/store/StoreListing.vue';
import Waiter from '../views/app/Waiter.vue';
import Login from '../views/auth/Login.vue';
import Splash from '../views/Splash.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Splash',
    component: Splash,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: '',
        name: 'StoreListing',
        component: StoreListing,
      },
      {
        path: 'store-add',
        name: 'StoreAdd',
        component: StoreAdd,
      },
      {
        path: 'store/:storeSlug',
        name: 'Store',
        component: Store,
        children: [
          {
            path: 'waiters',
            name: 'Waiter',
            component: Waiter,
            children: [
              {
                path: 'waiter/:waiterId',
                name: 'WaiterDetails',
                component: Waiter,
              },
            ],
          },
          {
            path: 'product/:productId',
            name: 'ProductDetails',
            component: ProductDetails,
          },
          {
            path: 'product-add',
            name: 'ProductAdd',
            component: ProductAdd,
          },
        ],
      },
    ],
  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, _, next) => {
  if (to.matched.some((record) => record.meta.authRequired)) {
    if (firebase.auth().currentUser) {
      next();
    } else {
      next({ path: 'login' });
    }
  } else {
    next();
  }
});

export default router;
