<template>
	<v-app>
		<div v-if="!isLoading && !isError">
			<v-app-bar app>
				<v-toolbar-title>
					<h4>{{ store.restaurant.name }}</h4>
				</v-toolbar-title>

				<v-spacer />
				<v-chip color="secondary" label>
					<v-icon left>mdi-table-furniture</v-icon>
					<h3>{{ cartData.desk }}</h3>
				</v-chip>
			</v-app-bar>

			<v-main>
				<v-list v-if="cart.length != 0" two-line flat>
					<v-list-item-group v-model="selectedProducts" multiple>
						<div v-for="(product, index) in cart" :key="product.id">
							<v-list-item
								active-class="primary--text text--darken-2"
								class="red--text text--darken-2"
							>
								<template v-slot:default="{ active }">
									<v-list-item-icon>
										<v-img
											:src="product.imageURL"
											height="100"
											width="100"
											contain
										/>
									</v-list-item-icon>

									<v-list-item-content align="start">
										<v-list-item-title>
											<h2>x{{ product.quantity }}</h2>
										</v-list-item-title>

										<v-list-item-subtitle>
											<h3>
												{{ product.name }}
											</h3>
										</v-list-item-subtitle>
									</v-list-item-content>

									<v-list-item-action>
										<v-checkbox
											:input-value="active"
											color="primary darken-2"
											class="red--text text--darken-2"
										></v-checkbox>
									</v-list-item-action>
								</template>
							</v-list-item>

							<v-divider v-if="index != cart.length - 1" />
						</div>
					</v-list-item-group>
				</v-list>

				<v-container v-else style="margin-top: 20vh">
					<v-row justify="center" align="center">
						<v-icon color="primary"> mdi-information-outline </v-icon>
						<h4 class="ml-2">Cart is empty!</h4>
					</v-row>
				</v-container>
			</v-main>

			<v-footer app color="white">
				<v-card
					class="py-1 mb-2"
					v-bind:class="{
						green: selectedProducts.length == cart.length,
						orange:
							selectedProducts.length > 0 &&
							selectedProducts.length < cart.length,
						red: selectedProducts.length == 0,
					}"
					width="100%"
				>
					<h2 class="white--text" v-if="selectedProducts.length > 0">
						{{
							selectedProducts
								.map((index) => cart[index].quantity)
								.reduce((a, b) => a + b)
						}}
						product ready
					</h2>

					<h2 class="white--text" v-else>0 product ready</h2>
				</v-card>
			</v-footer>

			<v-dialog v-model="showSuccessDialog">
				<v-card>
					<v-card-title class="pa-8">
						<v-row justify="center">
							<h3>Done preparing cart</h3>
						</v-row>
					</v-card-title>

					<v-card-text>
						<v-col>
							<v-row justify="center" class="pa-2">
								<v-icon color="green" x-large>
									mdi-check-circle-outline
								</v-icon>
								<!-- <h1>🍔🍟🍕</h1> -->
							</v-row>
							<v-row justify="center" class="pt-4">
								<h3>Now you can serve foods to table {{ cartData.desk }}</h3>
							</v-row>
						</v-col>
					</v-card-text>
					<v-card-actions>
						<v-spacer />

						<v-btn
							color="primary darken-1"
							text
							@click="showSuccessDialog = false"
						>
							OK
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>

		<div v-else>
			<v-dialog
				v-model="isError"
				persistent
				transition="dialog-bottom-transition"
				width="100%"
			>
				<v-card>
					<v-container class="py-8">
						<div class="d-flex justify-center mb-4">
							<v-icon size="40">mdi-emoticon-sad-outline</v-icon>
						</div>

						<p class="title text-center my-0 py-0 grey--text text--darken-3">
							Oops! Something totally went wrong
						</p>

						<div class="d-flex justify-center mt-6">
							<v-btn @click="onRefresh" color="primary">
								<v-icon class="mr-2">mdi-refresh</v-icon>
								REFRESH
							</v-btn>
						</div>
						<p class="body-2 text-center mt-6 py-0 grey--text text--darken-1">
							If problem persist kindly write us at bartuozel@gmail.com
						</p>

						<div class="d-flex justify-center mt-2">
							<v-btn @click="onMailClick" class="red--text">
								<v-icon class="mr-2">mdi-email-edit-outline</v-icon>
								MAIL US
							</v-btn>
						</div>
					</v-container>
				</v-card>
			</v-dialog>

			<v-dialog
				persistent
				v-model="isLoading"
				transition="dialog-bottom-transition"
				width="100"
			>
				<v-card>
					<v-progress-circular indeterminate color="primary" class="ma-4" />
				</v-card>
			</v-dialog>
		</div>
	</v-app>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Cart',

  data() {
    return {
      cartData: {},
      cart: [],
      showSuccessDialog: false,
      isLoading: true,
      isError: false,
      error: null,
      store: {},
      selectedProducts: [],
    };
  },
  methods: {
    async getStore() {
      this.cartData = JSON.parse(this.$route.query.data);

      axios
        .get(
          `https://cors-anywhere.herokuapp.com/https://food-client-api-gateway.getirapi.com/restaurants/${this.cartData.id}`,
        )
        .then((res) => {
          this.store = res.data.data;
          document.title = this.store.restaurant.name;

          const productIds = this.cartData.cart.map((product) => product.id);

          const products = [];
          this.store.productCategories.forEach((productCategory) => products.push(...productCategory.products));

          this.cart = products
            .filter((product) => productIds.includes(product.id))
            .filter(
              (thing, index, self) => self.findIndex((t) => t.id === thing.id) === index,
            )
            .map((product) => ({
              quantity: this.cartData.cart.filter(
                (cartProduct) => cartProduct.id === product.id,
              )[0].quantity,
              ...product,
            }));

          this.isLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.isLoading = false;
          this.isError = true;
          this.error = err;

          // // TODO: Testing only
          this.isError = false;
          document.title = this.store.restaurant.name;

          const productIds = this.cartData.cart.map((product) => product.id);

          const products = [];
          this.store.productCategories.forEach((productCategory) => products.push(...productCategory.products));

          this.cart = products
            .filter((product) => productIds.includes(product.id))
            .filter(
              (thing, index, self) => self.findIndex((t) => t.id === thing.id) === index,
            )
            .map((product) => ({
              quantity: this.cartData.cart.filter(
                (cartProduct) => cartProduct.id === product.id,
              )[0].quantity,
              ...product,
            }));
        });
    },
    onRefresh() {
      location.reload();
    },
    onMailClick() {
      window.open(
        'mailto:bartuozel@gmail.com?subject=Bug Report&body=Hello,\n',
      );
    },
  },
  async created() {
    await this.getStore();
  },
  watch: {
    selectedProducts(val) {
      if (val.length == this.cart.length) {
        this.showSuccessDialog = true;
      }
    },
  },
};
</script>
