<template>
	<v-app>
		<v-app-bar app>
			<v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

			<v-toolbar-title>
				<h4>Dashboard</h4>
			</v-toolbar-title>

			<v-spacer />

			<!-- <v-menu offset-y>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on">
						<v-icon>mdi-account-circle</v-icon>
					</v-btn>
				</template>

				<v-list dense>
					<v-list-item-group>
						<v-list-item>
							<v-list-item-actions class="pr-1">
								<v-icon> mdi-account </v-icon>
							</v-list-item-actions>

							<v-list-item-title>
								{{ user.email }}
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-actions class="pr-1">
								<v-icon> mdi-logout </v-icon>
							</v-list-item-actions>

							<v-list-item-title> Logout </v-list-item-title>
						</v-list-item>
					</v-list-item-group>
				</v-list>
			</v-menu> -->
			<v-menu
				v-model="menu"
				:close-on-content-click="false"
				:nudge-width="200"
				offset-x
			>
				<template v-slot:activator="{ on, attrs }">
					<v-btn v-bind="attrs" v-on="on">
						Account
						<v-icon right>mdi-account-circle</v-icon>
					</v-btn>
				</template>

				<v-card>
					<v-list>
						<v-list-item>
							<v-list-item-avatar v-if="user.photoURL">
								<img :src="user.photoURL" alt="photoURL" />
							</v-list-item-avatar>

							<v-list-item-content>
								<v-list-item-title>{{ user.email }}</v-list-item-title>
								<v-list-item-subtitle>{{ user.uid }}</v-list-item-subtitle>
							</v-list-item-content>

							<!-- <v-list-item-action>
								<v-btn :class="fav ? 'red--text' : ''" icon @click="fav = !fav">
									<v-icon>mdi-heart</v-icon>
								</v-btn>
							</v-list-item-action> -->
						</v-list-item>
					</v-list>

					<v-divider></v-divider>

					<v-list-item-group>
						<v-list-item @click="logout">
							<v-list-item-title>Logout</v-list-item-title>
						</v-list-item>
					</v-list-item-group>

					<v-card-actions>
						<v-spacer />
						<v-btn text @click="menu = false"> Close </v-btn>
					</v-card-actions>
				</v-card>
			</v-menu>
		</v-app-bar>

		<v-navigation-drawer v-model="drawer" absolute bottom temporary>
			<v-list nav dense>
				<v-list-item-group active-class="primary--text text--darken-4">
					<v-list-item>
						<v-list-item-title>Foo</v-list-item-title>
					</v-list-item>

					<v-list-item>
						<v-list-item-title>Bar</v-list-item-title>
					</v-list-item>

					<v-list-item>
						<v-list-item-title>Fizz</v-list-item-title>
					</v-list-item>

					<v-list-item>
						<v-list-item-title>Buzz</v-list-item-title>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>

		<router-view />
	</v-app>
</template>

<script>
import firebase from "@/firebase";

export default {
	name: "Dashboard",
	metaInfo: {
		title: "Nearbs | Dashboard",
	},
	components: {},
	data() {
		return {
			drawer: false,
			menu: false,
		};
	},
	methods: {
		logout() {
			firebase.auth().signOut();
		},
	},
	computed: {
		user() {
			return this.$store.getters.user;
		},
		stores() {
			return this.$store.getters.stores;
		},
	},
};
</script>
