<template>
	<v-main>
		<v-container v-if="stores.length != 0" fluid pa-6>
			<v-row>
				<v-col v-for="store in stores" :key="store.id" cols="12" sm="4">
					<StoreCard :store="store" />
				</v-col>
			</v-row>
		</v-container>

		<v-container v-else fluid fill-height>
			<v-col>
				<v-row justify="center" align="center">
					<v-icon color="error"> mdi-information-outline </v-icon>
					<h3 class="ml-2">You dont have any store</h3>
				</v-row>
				<v-row justify="center" align="center" class="pt-4">
					<v-btn color="primary darken-3" to="dashboard/store-add" plain>
						Add Store
					</v-btn>
				</v-row>
			</v-col>
		</v-container>
	</v-main>
</template>

<script>
import StoreCard from "@/components/StoreCard.vue";

export default {
	name: "StoreListing",
	metaInfo: {
		title: "Nearbs | Dashboard",
	},
	components: {
		StoreCard,
	},
	data() {
		return {};
	},
	methods: {},
	async created() {},
	computed: {
		user() {
			return this.$store.getters.user.name ?? "";
		},
		stores() {
			return this.$store.getters.stores;
		},
	},
};
</script>
