<template>
	<v-app>
		<v-main>
			<v-container fluid fill-height>
				<v-layout align-center justify-center>
					<v-progress-circular
						indeterminate
						color="primary"
						size="60"
						width="4"
					/>
				</v-layout>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
import firebase from '@/firebase';

export default {
  name: 'Splash',
  created() {
    const self = this;

    const timer = setInterval(() => {
      if (firebase.auth().currentUser == null) {
        self.$router.push('login');
        clearInterval(timer);
      }
    }, 1000);
  },
};
</script>
