// import { storeApi } from '../api';
import Vue from 'vue';
import Vuex from 'vuex';
import { store } from '../constant/tempData';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {},
    stores: [],
  },
  getters: {
    user(state) {
      return state.user;
    },
    stores(state) {
      return state.stores;
    },
    store(state) {
      return state.stores.find((store) => store.slug === this.$route.params.storeSlug);
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setStore(state, stores) {
      state.stores = stores;
    },
  },
  actions: {
    async getDash({ commit }) {
      // const asyncRes = await Promise.all(state.user.stores.map(async (storeSlug) => {
      //   return await storeApi.getStore(storeSlug);
      // }));

      // commit.dispatch('setStore', asyncRes);
      commit('setStore', [store, store, store, store]);
    },
  },
});
