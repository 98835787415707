<template>
	<v-app>
		<v-main>
			<v-container fluid fill-height>
				<v-layout align-center justify-center>
					<v-flex xs12 sm8 md4>
						<v-card class="elevation-12">
							<v-toolbar dark color="primary darken-3">
								<v-toolbar-title>Add Store</v-toolbar-title>
							</v-toolbar>
							<v-card-text>
								<v-form>
									<!-- <v-text-field
										prepend-icon="mdi-email"
										v-model="email"
										:error-messages="emailErrors"
										label="E-mail"
										type="email"
										required
										@input="$v.email.$touch()"
										@blur="$v.email.$touch()"
									></v-text-field> -->
									<v-combobox
										v-model="cuisine"
										:items="cuisineOptions"
										label="cuisine"
										prepend-icon="mdi-storefront"
									></v-combobox>
									<!-- <v-text-field
										prepend-icon="mdi-lock"
										v-model="password"
										:error-messages="passwordErrors"
										label="Password"
										type="password"
										required
										@change="$v.password.$touch()"
										@blur="$v.password.$touch()"
									></v-text-field> -->
								</v-form>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="primary" @click="add">Add</v-btn>
							</v-card-actions>
						</v-card>
					</v-flex>
				</v-layout>
			</v-container>
		</v-main>

		<v-snackbar v-model="errorSnackbar" timeout="6000">
			{{ error }}
			<template v-slot:action="{ attrs }">
				<v-btn color="error" text v-bind="attrs" @click="errorSnackbar = false">
					Close
				</v-btn>
			</template>
		</v-snackbar>

		<v-snackbar v-model="successSnackbar" timeout="6000">
			Store Created Successfully
			<template v-slot:action="{ attrs }">
				<v-btn
					color="success"
					text
					v-bind="attrs"
					@click="successSnackbar = false"
				>
					OK
				</v-btn>
			</template>
		</v-snackbar>
	</v-app>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, alpha } from "vuelidate/lib/validators";
import firebase from "../../../firebase";

export default {
	name: "AddStore",
	metaInfo: {
		title: "Nearbs | Add Store",
	},
	mixins: [validationMixin],
	validations: {
		name: { required, alpha },
		cuisine: { required },
	},
	data: () => ({
		successSnackbar: false,
		errorSnackbar: false,
		error: "",
		name: "",
		cuisine: "",
		cuisineOptions: [
			"Cuisine unspecified",
			"American food",
			"Asian food",
			"Brazilian food",
			"Breakfast",
			"Brunch",
			"Chicken",
			"Chinese food",
			"Family style cuisine",
			"Fast food",
			"French food",
			"Greek food",
			"German food",
			"Hamburger",
			"Indian food",
			"Indonesian food",
			"Italian food",
			"Japanese food",
			"Korean food",
			"Latin American food",
			"Mediterranean food",
			"Mexican food",
			"Pakistani food",
			"Pizza",
			"Seafood",
			"Spanish food",
			"Sushi",
			"Thai food",
			"Turkish food",
			"Vegetarian Food",
			"Vietnamese food",
			"Other cuisine",
		],
	}),
	computed: {
		// passwordErrors() {
		// 	const errors = [];
		// 	if (!this.$v.password.$dirty) return errors;
		// 	!this.$v.password.required && errors.push("Password is required.");
		// 	return errors;
		// },
		// emailErrors() {
		// 	const errors = [];
		// 	if (!this.$v.email.$dirty) return errors;
		// 	!this.$v.email.email && errors.push("Must be valid e-mail");
		// 	!this.$v.email.required && errors.push("E-mail is required");
		// 	return errors;
		// },
	},
	methods: {
		add() {
			this.$v.$touch();
			if (!this.$v.$anyError) {
				firebase
					.firestore()
					.collection("store")
					.add({})
					.then(() => {
						this.successSnackbar = true;
						this.$router.push("/dashboard");
					})
					.catch((err) => {
						this.errorSnackbar = true;
						this.error = err;
					});
			}
		},
	},
};
</script>
