import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyDrRaLqJixzweuIpT7reZs30RUtcA7wWh0',
  authDomain: 'nearbs-app.firebaseapp.com',
  projectId: 'nearbs-app',
  storageBucket: 'nearbs-app.appspot.com',
  messagingSenderId: '1060592540745',
  appId: '1:1060592540745:web:7900a06bafca0fce193398',
  measurementId: 'G-06B930L45G',
};

export default !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
