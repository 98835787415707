<template>
	<v-app>
		<v-app-bar app>
			<v-img
				contain
				max-height="70%"
				max-width="60"
				src="@/assets/logo.png"
			></v-img>

			<v-toolbar-title class="font-weight-black headline">
				Nearbs Admin Login
			</v-toolbar-title>
		</v-app-bar>

		<v-main>
			<v-container fluid fill-height>
				<v-layout align-center justify-center>
					<v-flex xs12 sm8 md4>
						<v-card class="elevation-12">
							<v-toolbar dark color="primary darken-3">
								<v-toolbar-title>Login form</v-toolbar-title>
							</v-toolbar>
							<v-card-text>
								<v-form>
									<v-text-field
										prepend-icon="mdi-email"
										v-model="email"
										:error-messages="emailErrors"
										label="E-mail"
										type="email"
										required
										@input="$v.email.$touch()"
										@blur="$v.email.$touch()"
									></v-text-field>
									<v-text-field
										prepend-icon="mdi-lock"
										v-model="password"
										:error-messages="passwordErrors"
										label="Password"
										type="password"
										required
										@change="$v.password.$touch()"
										@blur="$v.password.$touch()"
									></v-text-field>
								</v-form>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="primary" @click="login">Login</v-btn>
							</v-card-actions>
						</v-card>
					</v-flex>
				</v-layout>
			</v-container>
		</v-main>

		<v-snackbar v-model="errorSnackbar" timeout="6000">
			{{ error }}
			<template v-slot:action="{ attrs }">
				<v-btn color="error" text v-bind="attrs" @click="errorSnackbar = false">
					Close
				</v-btn>
			</template>
		</v-snackbar>
	</v-app>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import firebase from "@/firebase";

export default {
	name: "Login",
	metaInfo: {
		title: "Nearbs | Login",
	},
	mixins: [validationMixin],
	validations: {
		email: { required, email },
		password: { required },
	},
	data: () => ({
		errorSnackbar: false,
		error: "",
		email: "",
		password: "",
	}),
	computed: {
		passwordErrors() {
			const errors = [];
			if (!this.$v.password.$dirty) return errors;
			!this.$v.password.required && errors.push("Password is required.");
			return errors;
		},
		emailErrors() {
			const errors = [];
			if (!this.$v.email.$dirty) return errors;
			!this.$v.email.email && errors.push("Must be valid e-mail");
			!this.$v.email.required && errors.push("E-mail is required");
			return errors;
		},
	},
	methods: {
		login() {
			this.$v.$touch();

			if (!this.$v.$anyError) {
				firebase
					.auth()
					.signInWithEmailAndPassword(this.email, this.password)
					.then((userCredentials) => {
						this.$store.commit("setUser", userCredentials.user);
						this.$router.push("dashboard");
					})
					.catch((error) => {
						this.errorSnackbar = true;
						this.error = error.message;
					});
			}
		},
	},
};
</script>
