import Vue from 'vue';
import VueMeta from 'vue-meta';
import Vuelidate from 'vuelidate';
import VueClipboard from 'vue-clipboard2';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import firebase from './firebase';

Vue.config.productionTip = false;

Vue.use(VueMeta);
Vue.use(Vuelidate);
Vue.use(VueClipboard);

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    store.commit('setUser', user);
    store.dispatch('getDash');
    router.push('/dashboard');
  } else {
    store.commit('setUser', user);
    router.push('/login');
  }
});

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
