<template>
	<v-card :loading="loading" class="mx-auto">
		<template slot="progress">
			<v-progress-linear
				color="deep-purple"
				height="10"
				indeterminate
			></v-progress-linear>
		</template>

		<v-img
			v-if="store.images[0] != null || store.images[0] != ''"
			:src="store.images[0]"
			height="200"
			cover
		/>

		<v-icon v-else> mdi-information-outline </v-icon>

		<v-card-title>
			{{ store.name }}
		</v-card-title>

		<v-card-text>
			<v-row align="start" class="mx-0">
				<v-rating
					:value="4.5"
					color="amber"
					dense
					half-increments
					readonly
					size="14"
				></v-rating>

				<div class="grey--text ms-4">4.5 (413)</div>
			</v-row>

			<div class="my-4 text-subtitle-1">$ • Italian, Cafe</div>

			<!-- <div>
				Small plates, salads & sandwiches - an intimate setting with 12 indoor
				seats plus patio seating.
			</div> -->
		</v-card-text>

		<v-divider class="mx-4"></v-divider>

		<v-card-title>Today</v-card-title>

		<v-card-text>
			<v-chip-group colmn>
				<v-chip>5:30AM</v-chip>

				<v-chip>12:30PM</v-chip>
			</v-chip-group>
		</v-card-text>

		<v-card-actions>
			<v-btn color="deep-purple lighten-2" text @click="reserve">
				Reserve
			</v-btn>
		</v-card-actions>
	</v-card>
	<!-- <div class="shadow pa-2">
		<v-row class="ma-0">
			<v-col cols="4" class="pa-0">
				<v-img
					v-if="store.images[0] != null || store.images[0] != ''"
					:src="store.images[0]"
					class="shadow fill-height"
					contain
				/>

				<v-icon v-else> mdi-information-outline </v-icon>
			</v-col>

			<v-col cols="8" class="pl-5" align-content="space-between" flex>
				<v-row>
					<h3>{{ store.name }}</h3>
				</v-row>

				<v-row>
					<h6 class="subtitle"></h6>
				</v-row>
			</v-col>
		</v-row>
	</div> -->
</template>



<script >
export default {
	name: "StoreCard",
	props: {
		store: Object,
	},
	data: function () {
		return {};
	},
	methods: {},
	created: async function () {},
};
</script>